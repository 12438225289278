import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Solving Complex Business Challenges "
const pageTitle = "Solving Complex Business Challenges"
const subTitle = "Business Process"
const para =
  "Our teams work in all segments of financial services including investment banking-( front office, middle office and back office,) retail banking and wealth management. This allows us to apply tools to all aspects and processes of your business."
const blockquote = `"Outsourcing of core information-related business activities"`

const cardItems = [
  {
    id: 1,
    heading: <h2>Some Examples:</h2>,
    listItems: (
      <>
        <h3>Credit Risk</h3>
        <li>
          <Icon.Square />
          Atyeti team is providing Subject matter expertise to Credit Officers
          to understand and evaluate associated Risk and make relevant
          investment decision.
        </li>
        <li>
          <Icon.Square />
          Supporting the Risk management Team in making effective and timely
          decisions by meeting Reporting deadlines- helping to make timely
          trading decisions.
        </li>
        <li>
          <Icon.Square />
          Technology used : SQL database, Traders front end booking S/W,
          specialized tools to calculate VAR numbers.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Front Office Finance`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Provided the Front Office team with an architectural solution to
          provide all downstream applications with Front office data.
        </li>
        <li>
          <Icon.Square />
          Eliminate multiple Front to Back office reconciliation in the
          architecture - Atyeti team built a tool to facilitate this activity.
        </li>
        <li>
          <Icon.Square />
          Atyeti associates experienced in Cash Equities for Front office
          sourcing (Single source of Data, FO-BO) helped build a normalized and
          repeatable data model. This included identifying and improving data
          quality controls, building processes and templates around data
          delivery, data governance and operational support etc.{" "}
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Financial Regulation`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti team is responsible for reports submitted to regulators in the
          G-20 jurisdiction.
        </li>
        <li>
          <Icon.Square />
          Responsible for reconciliation of reporting data.
        </li>
        <li>
          <Icon.Square />
          The solution provides an excellent picture of over- reporting,
          under-reporting and reporting errors. It helps in rectifying the
          reporting logic and avoid penalties from regulators and auditors.
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `International Wealth Management`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti team supports IWM /Swiss Universal Banking entities with G20
          and FINMA regulations - mandating Listed and OTC derivatives
          trades/valuations/positions/collateral to be reported to external
          repositories. within the project.
        </li>
        <li>
          <Icon.Square />
          Built in-house reporting solution for meeting BCBS 239 regulatory
          obligation by identifying and documenting critical data elements,
          their lineage and their owners that are then used for reporting and
          aggregation purposes– so as to establish various controls on those
          critical data elements.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query SecondQuery {
    casedetailsJson(slug: { eq: "solving-complex-business-challenges" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
